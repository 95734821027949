import moment from "moment";
import React from "react";
import ReactHtmlParser from "react-html-parser";
import Modal from "react-modal";
import { CalendarEventModel } from "./calendar-event.model";
import CalendarEventTime from "./CalendarEventTime";

const customStyles: { content: React.CSSProperties } = {
  content: {
    width: "750px",
    height: "90%",
    display: "flex",
    flexDirection: "column",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    zIndex: 99,
  },
};

export default function CalendarEventModal({
  calendarEvent,
  open,
  onClose,
}: {
  calendarEvent: CalendarEventModel;
  open: boolean;
  onClose: () => void;
}): JSX.Element {
  return (
    <Modal isOpen={open} onRequestClose={onClose} style={customStyles}>
      <h1 className="modal-title" style={{ marginBlockStart: 0 }}>
        {calendarEvent.title}
      </h1>
      <h2 className="modal-subtitle">Game</h2>
      <p>{calendarEvent.game}</p>

      <h2 className="modal-subtitle">Type</h2>
      <p>{calendarEvent.type}</p>

      <h2 className="modal-subtitle">Date</h2>
      <p>
        {moment(calendarEvent.date, moment.HTML5_FMT.DATE).format("yyyy-MM-DD")}
      </p>

      <h2 className="modal-subtitle">Start</h2>
      <CalendarEventTime time={calendarEvent.time} />

      <h2 className="modal-subtitle">About</h2>
      {ReactHtmlParser(calendarEvent.description)}

      <h2 className="modal-subtitle">Event prize structure</h2>
      {ReactHtmlParser(calendarEvent.prizeStructure)}

      <button
        className="calendar-button"
        onClick={onClose}
        style={{ marginTop: "auto" }}
      >
        Close
      </button>
    </Modal>
  );
}
