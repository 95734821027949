import React from "react";
import ReactDOM from "react-dom";
import Modal from "react-modal";
import App from "./App";
import "./index.css";

const rootId = "big-calendar";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById(rootId)
);
Modal.setAppElement(`#${rootId}`);
