import React, { useState } from "react";
import { CalendarEventModel } from "./calendar-event.model";
import CalendarEventModal from "./CalendarEventModal";
import CalendarEventTime from "./CalendarEventTime";

export default function CalendarEvent({
  calendarEvent,
}: {
  calendarEvent: CalendarEventModel;
}): JSX.Element {
  const [open, setOpen] = useState(false);

  const handleClose = (): void => setOpen(false);

  const handleView = (): void => setOpen(true);

  return (
    <>
      <div className="calendar-event" key={calendarEvent.id}>
        <CalendarEventTime time={calendarEvent.time} />
        <div className="icon">
          <img
            src={calendarEvent.calendarIcon}
            alt="Icon"
            width="41"
            height="27"
          />
        </div>
        <div className="title">{calendarEvent.title}</div>
        <button className="calendar-button" onClick={handleView}>
          VIEW DETAILS
        </button>
      </div>
      <CalendarEventModal
        calendarEvent={calendarEvent}
        open={open}
        onClose={handleClose}
      />
    </>
  );
}
