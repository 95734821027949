import ErrorResponseModel from "../error-response.model";
import { CalendarEventModel } from "./calendar-event.model";

const API_URL = "https://portal.binderpos.com/api";

export async function getEvents({
  startDate,
  endDate,
}: {
  startDate: string;
  endDate: string;
}): Promise<CalendarEventModel[] | ErrorResponseModel> {
  const response = await fetch(
    API_URL + `/events/forStore?startDate=${startDate}&endDate=${endDate}`
  );
  return response.json();
}
