import React from "react";

export default function CalendarEventTime({
  time,
}: {
  time: string;
}): JSX.Element {
  const timeSplit = time.split(":");
  let hours = Number(timeSplit[0]) % 12;
  hours = hours === 0 ? 12 : hours;
  const minutes = Number(timeSplit[1]).toString().padStart(2, "0");
  const amPm = Number(timeSplit[0]) >= 12 ? "PM" : "AM";

  return (
    <div className="time">
      <span className="hours-minutes">{hours + ":" + minutes}</span>
      <span className="am-pm">{amPm}</span>
    </div>
  );
}
