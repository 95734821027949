import React from "react";
import Modal from "react-modal";

const customStyles = {
  content: {
    width: "750px",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    zIndex: 99,
  },
};

export default function ErrorModal({
  open,
  onClose,
  error,
}: {
  open: boolean;
  onClose: () => void;
  error: { title: string; message: string };
}): JSX.Element {
  return (
    <Modal isOpen={open} onRequestClose={onClose} style={customStyles}>
      <h1 className="modal-title" style={{ marginBlockStart: 0 }}>
        {error.title}
      </h1>
      <p>{error.message}</p>

      <button className="calendar-button" onClick={onClose}>
        Close
      </button>
    </Modal>
  );
}
