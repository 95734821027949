import moment from "moment";
import React, { useEffect, useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "./App.scss";
import ErrorModal from "./ErrorModal";
import CalendarEvent from "./calendar-event/CalendarEvent";
import { CalendarEventModel } from "./calendar-event/calendar-event.model";
import { getEvents } from "./calendar-event/calendar-event.service";
import ErrorResponseModel from "./error-response.model";

function App(): JSX.Element {
  const [date, setDate] = useState<Date>(new Date());
  const [events, setEvents] = useState<CalendarEventModel[]>([]);
  const [loading, setLoading] = useState(true);
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState<{ title: string; message: string }>({
    title: "",
    message: "",
  });

  useEffect(() => {
    setLoading(true);
    const now = moment(date).format("yyyy-MM-DD");
    getEvents({ startDate: now, endDate: now })
      .then((calendarEvents) => {
        if (Array.isArray(calendarEvents)) {
          return setEvents(
            calendarEvents.sort((a, b) => {
              const timeA = moment(a.time, "HH:mm:ss");
              const timeB = moment(b.time, "HH:mm:ss");
              return timeA.diff(timeB);
            }) as CalendarEventModel[]
          );
        }
        const errorResponse = calendarEvents as ErrorResponseModel;
        setError({
          title: errorResponse.error,
          message: errorResponse.detailedMessage,
        });
        setShowError(true);
      })
      .finally(() => setLoading(false));
  }, [date]);

  const handleDate = (date: Date | Date[]): void => {
    if (Array.isArray(date)) throw Error("Multiple dates passed");
    return setDate(date);
  };

  return (
    <div className="centered">
      <h1 className="calendar-title">EVENT CALENDAR</h1>

      <div className="calendar">
        <Calendar
          data-testid="calendar-input"
          onChange={handleDate}
          value={date}
        />
        <div className="schedule">
          <div className="schedule-title">{date.toDateString()}</div>
          {loading ? (
            <em>Getting events...</em>
          ) : events.length ? (
            events.map((calendarEvent) => (
              <CalendarEvent
                calendarEvent={calendarEvent}
                key={calendarEvent.id}
              />
            ))
          ) : (
            <>No events for this day.</>
          )}
        </div>
      </div>

      <ErrorModal
        open={showError}
        error={error}
        onClose={(): void => setShowError(false)}
      />
    </div>
  );
}

export default App;
